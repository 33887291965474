import { SkipToContent } from '@krakentech/coral';
import { GetServerSideProps } from 'next';
import { FC } from 'react';

import { AbTestVariantIdContext } from '@/components/contexts/abTestVariantId';
import { DomesticRewardContext } from '@/components/contexts/domesticReward';
import { StoryblokBannersContext } from '@/components/contexts/storyblokBanners';
import {
	StoryBlokFuelMixContext,
	StoryBlokFuelMixContextProp,
} from '@/components/contexts/storyblokFuelMix';
import { HomePage } from '@/components/pages/home/HomePage';
import {
	BannerItemFragment,
	BlogpostItemFragment,
} from '@/services/graphql-storyblok';
import { AB_TEST_SHORTCUT_HERO, getABTestVariantId } from '@/utils/ab-testing';
import { getActiveDomesticSignupRewardScheme } from '@/utils/api/getActiveDomesticSignupRewardScheme';
import { getBannerStories } from '@/utils/api/getBannerStories';
import { getFeaturedBlogStories } from '@/utils/api/getFeaturedBlogStories';
import { getStoryRichText } from '@/utils/api/getStoryRichText';
import { getI18nProps } from '@/utils/i18n/getI18nProps';
import { logError } from '@/utils/logger';
import { setCacheHeaderForVercelEdgeNetwork } from '@/utils/vercel';

type HomeProps = {
	activeDomesticSignupRewardSchemeCode?: string;
	bannerStories: BannerItemFragment[];
	featuredBlogStories: BlogpostItemFragment[];
	fuelMixStories: StoryBlokFuelMixContextProp;
	serversideAbTestVariantId?: string | null;
};

const Home: FC<HomeProps> = ({
	activeDomesticSignupRewardSchemeCode,
	bannerStories = [],
	featuredBlogStories,
	fuelMixStories,
	serversideAbTestVariantId,
}) => (
	<DomesticRewardContext.Provider
		value={{ activeDomesticSignupRewardSchemeCode }}
	>
		<StoryBlokFuelMixContext.Provider value={fuelMixStories}>
			<AbTestVariantIdContext.Provider
				value={{ variantId: serversideAbTestVariantId }}
			>
				<SkipToContent id="maincontent" label="メインコンテンツまでスキップ" />
				<StoryblokBannersContext.Provider value={{ bannerStories }}>
					<HomePage featuredBlogStories={featuredBlogStories} />
				</StoryblokBannersContext.Provider>
			</AbTestVariantIdContext.Provider>
		</StoryBlokFuelMixContext.Provider>
	</DomesticRewardContext.Provider>
);

const getFuelMixStories = async () => {
	const [fuelMixGreen, fuelMixSimple] = await Promise.all([
		getStoryRichText('pages/fuel-mix-green').catch((error) => {
			logError('Error fetching Storyblok fuel-mix-green data.', error);
			return null;
		}),
		getStoryRichText('pages/fuel-mix-simple').catch((error) => {
			logError('Error fetching Storyblok fuel-mix-simple data.', error);
			return null;
		}),
	]);
	return { fuelMixGreen, fuelMixStandard: null, fuelMixSimple };
};

export const getServerSideProps: GetServerSideProps<HomeProps> = async (
	context
) => {
	setCacheHeaderForVercelEdgeNetwork(context.res);

	const [
		activeDomesticSignupRewardSchemeCode,
		bannerStories,
		featuredBlogStories,
		i18nProps,
		fuelMixStories,
	] = await Promise.all([
		getActiveDomesticSignupRewardScheme()
			.then((data) => data?.activeDomesticSignupRewardScheme?.code ?? '')
			.catch((error) => {
				logError('Error calling getActiveDomesticSignupRewardScheme', error);
				return '';
			}),
		getBannerStories().catch((error) => {
			logError('Error calling getBannerStories', error);
			return [];
		}),
		getFeaturedBlogStories('data/featured-blog-landing-page').catch((error) => {
			logError('Error calling getFeaturedBlogStories', error);
			return [];
		}),
		getI18nProps(['common', 'account', 'obj']).catch((error) => {
			logError('Error calling getI18nProps', error);
			return {};
		}),
		getFuelMixStories().catch((error) => {
			logError('Error calling getStoryblokGreenFuelMix.', error);
			return { fuelMixGreen: null, fuelMixStandard: null, fuelMixSimple: null };
		}),
	]);

	return {
		props: {
			bannerStories,
			activeDomesticSignupRewardSchemeCode,
			featuredBlogStories,
			fuelMixStories,
			serversideAbTestVariantId: getABTestVariantId(
				AB_TEST_SHORTCUT_HERO,
				context
			),
			...i18nProps,
		},
	};
};

export default Home;
